













import Vue from 'vue';
import { NeIcon } from '@ne/ne-vue2-lib';

export default Vue.extend({
  name: 'GoBackLink',
  components: {
    NeIcon
  }
});
