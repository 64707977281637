



















import Vue from 'vue';
import { NeSection } from '@ne/ne-vue2-lib';
import GoBackLink from '@/shared/components/GoBackLink/GoBackLink.vue';

export default Vue.extend({
  name: 'Help',
  components: {
    NeSection,
    GoBackLink
  }
});
